import { NgModule } from '@angular/core';
import { addNavMenuSection } from '@vendure/admin-ui/core';

/**
 * This module adds a new nav menu section linking to the Vue app and React app routes.
 */
@NgModule({
  providers: [
    addNavMenuSection(
      {
        id: 'demo-extensions',
        label: 'apoki Aktionen',
        items: [
          {
            id: 'vue-app',
            label: 'Rezept-Bestellungen',
            routerLink: ['/extensions/vue-app'],
            icon: 'code',
          },
        ],
      },
      'settings'
    ),
  ],
})
export class SharedUiExtensionModule {}
