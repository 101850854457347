import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiExtensionModule } from './extensions/7eeb8ca0327df37bed7ccce6351dc0738ee68d48e6fc61be73524c779a584505/shared-ui-extension.module';



@NgModule({
    imports: [CommonModule, SharedUiExtensionModule],
    providers: [],
})
export class SharedExtensionsModule {}
